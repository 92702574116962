import React, { useState } from 'react'
import JobPost from '../../pages/work-with-us';
import ModalDialog from '../common/ModalDialog';

function LawSection() {
  const [workWithUsDialog, setWorkWithUsDialog] = useState(false);
  return (
    <div className="container-fluid mx-0 px-0 py-5 bg-primary">
      <div className="law-section fit-width">
        <h2 className="text-white">LawAdvisor is powering the world’s <br /> most pioneering law firms and <br /> corporate legal departments.</h2>
        <button onClick={() => setWorkWithUsDialog(true)} className="btn btn-outline text-white">Find out how</button>

        {workWithUsDialog && <ModalDialog closeAction={() => setWorkWithUsDialog(false)} scroll={true}>
          <div className="container px-0">
            <JobPost />
          </div>
        </ModalDialog>}
      </div>
    </div>
  )
}

export default LawSection;
